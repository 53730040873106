import React from "react";
import ValidationAlert from "../components/ValidationAlert";
import utils from "../../../utils";
import { getLabel, getLabelAsString } from "../formRenderHelper";

function TextareaInput({ control, handleChange, formOption }) {
  const { name, label, type, value, errors, style, helpText, attrs } = control;
  const fieldId = utils.random.shortId();
  const fieldLabel = getLabel(control, formOption);
  //const { template = 'bootstrap', usePlaceholder = false } = formOption;
  const { usePlaceholder = false } = formOption;
  const placeHolder = usePlaceholder
    ? getLabelAsString(control, formOption)
    : null;
  const valueStr = value === 0 ? 0 : value || "";

  const textAreaRef = React.useRef(null);
  const [textAreaHeight, setTextAreaHeight] = React.useState("auto");
  const [parentHeight, setParentHeight] = React.useState("auto");

  React.useEffect(() => {
    const disableAutoHeight = (style && style.disableAutoHeight) || false;
    if (!disableAutoHeight) {
      if (textAreaRef.current) {
        const h =
          textAreaRef.current.scrollHeight > 60
            ? textAreaRef.current.scrollHeight
            : 60;
        setParentHeight(`${h}px`);
        setTextAreaHeight(`${h}px`);
      }
    }
  }, [valueStr, style]);

  function onChangeInternal(e) {
    const disableAutoHeight = (style && style.disableAutoHeight) || false;
    if (!disableAutoHeight) {
      setTextAreaHeight("auto");
      if (textAreaRef.current) {
        const h =
          textAreaRef.current.scrollHeight > 60
            ? textAreaRef.current.scrollHeight
            : 60;
        setParentHeight(`${h}px`);
      }
    }
    handleChange(e, control);
  }

  return (
    <div className={`ff ff--textarea form-group ${style && style.className}`}>
      {!usePlaceholder && fieldLabel && (
        <label htmlFor={fieldId} className="label">
          {fieldLabel}
        </label>
      )}
      <div className="ff__input" style={{ minHeight: parentHeight }}>
        <textarea
          id={fieldId}
          name={name}
          value={valueStr}
          type={type}
          onChange={onChangeInternal}
          placeholder={placeHolder}
          rows={style && style.rows}
          ref={textAreaRef}
          style={{ height: textAreaHeight }}
          className={
            "form-control" + (errors && errors.length > 0 ? " is-invalid" : "")
          }
          {...attrs}
        ></textarea>
      </div>
      {helpText && (
        <div
          className="ff__help"
          dangerouslySetInnerHTML={{ __html: helpText }}
        ></div>
      )}
      <ValidationAlert errors={errors} />
    </div>
  );
}

export default TextareaInput;
