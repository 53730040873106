import { fb, validators } from "../../../lib/form";

export function getBookingModel(data) {
  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Name", type: "text" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone", type: "tel" },
    ],
    date: [
      data.date || "",
      [validators.Required()],
      { label: "Date", type: "date" },
    ],
    time: [
      data.time || "",
      [validators.Required()],
      { label: "Time", type: "time" },
    ],
    pickUpAddress: [
      data.pickUpAddress || "",
      [validators.Required()],
      { label: "Pick-up Address", type: "text" },
    ],
    dropOffAddress: [
      data.dropOffAddress || "",
      [validators.Required()],
      { label: "Drop-off Address", type: "text" },
    ],
    enquiry: [
      data.enquiry || "",
      [validators.Required()],
      { label: "Your Message", type: "textarea" },
    ],
    attachments: [
      data.attachments || "",
      [],
      { label: "Attachments", type: "file" },
    ],
  });
  return model;
}
