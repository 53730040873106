const defaultHolder = typeof window !== `undefined` ? window : {};
const storeName = '__j6_lazy_js'

export function loadJs(...scripts) {
  const promises = [];
  scripts.forEach((script) => promises.push(loadScript(script, defaultHolder)));
  return Promise.all(promises);
}

export function loadedJs(url, holder = defaultHolder) {
  if (!holder[storeName]) return false;
  return holder[storeName][url] && holder[storeName][url].loaded
}

export function loadScript(url, holder_, forceToReload = false) {
  const holder = holder_ || defaultHolder
  if (!holder[storeName]) holder[storeName] = {};

  return new Promise((resolve, reject) => {
    //resolve if already loaded
    if (!forceToReload && holder[storeName][url] && holder[storeName][url].loaded) {
      resolve({ script: url, loaded: true, networkLoaded: false , status: 'Already Loaded' });
    }
    else {
      // remove previous
      const existing = document.querySelector('script[src="' + url +'"]')
      if (existing) existing.remove();

      // load script
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => {
        holder[storeName][url] = { loaded: true };
        resolve({ script: url, loaded: true, networkLoaded: true, status: 'Loaded' });
      };
      script.onerror = (error) => resolve({ script: url, loaded: false, status: 'Load failed' });
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  });
}