import React, { useEffect, useRef } from "react";

export function AddressAutocomplete({
  value,
  onChangeAddress,
  name,
  setError,
  ...props
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    const nswBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(-37.5052801, 140.9992793),
      new window.google.maps.LatLng(-28.1579637, 153.6386914)
    );

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["establishment", "geocode"],
        componentRestrictions: { country: "au" },
        bounds: nswBounds,
        strictBounds: false,
        fields: ["formatted_address", "name", "address_components", "geometry"],
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      const isInNSW = place.address_components.some(
        (component) =>
          (component.short_name === "NSW" ||
            component.long_name === "New South Wales") &&
          component.types.includes("administrative_area_level_1")
      );

      if (isInNSW) {
        let formattedAddress = place.formatted_address;

        if (place.name && !formattedAddress.startsWith(place.name)) {
          formattedAddress = `${place.name}, ${formattedAddress}`;
        }

        onChangeAddress({
          persist: () => {},
          target: {
            name: name,
            value: formattedAddress,
          },
        });
        setError(name, null);
      } else {
        inputRef.current.value = "";
        onChangeAddress({
          persist: () => {},
          target: {
            name: name,
            value: "",
          },
        });

        setError(name, "Address must be in New South Wales");
      }
    });
  }, [onChangeAddress, name, setError]);

  return (
    <input
      className='address-input google-autocomplete'
      ref={inputRef}
      type='text'
      name={name}
      value={value}
      onChange={(e) => onChangeAddress(e)}
      placeholder='Enter address in New South Wales, Australia'
      {...props}
    />
  );
}
