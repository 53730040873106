/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../cms/utils/cmsUtils";
import { useLayoutEffect, useRef } from "react";

export function JotformIframe(props) {
  const item = props.item;
  const cssClass = props.cssClass;
  const code = cmsUtils.payload(item, "Code") || "";
  const iframeRef = useRef(null);

  const extractScripts = (htmlString) => {
    const scriptRegex = /<_script[\s\S]*?<\/_script>/gi;
    return htmlString.match(scriptRegex) || [];
  };

  const removeScripts = (htmlString) => {
    return htmlString.replace(/<_script[\s\S]*?<\/_script>/gi, "");
  };

  const scriptCodes = extractScripts(code);
  const nonScriptCode = removeScripts(code).trim();

  useLayoutEffect(() => {
    const existingScripts = document.querySelectorAll(
      'script[id^="jotformScript"]'
    );
    existingScripts.forEach((script) => script.remove());

    const loadScriptsSequentially = (scripts, index = 0) => {
      if (index >= scripts.length) {
        if (window.jotformEmbedHandler && iframeRef.current) {
          const iframe = iframeRef.current.querySelector("iframe");
          if (iframe) {
            window.jotformEmbedHandler(
              `iframe[id='${iframe.id}']`,
              "https://form.jotform.com/"
            );
          }
        }
        return;
      }

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = `jotformScript-${index}`;

      if (scripts[index].includes("src=")) {
        const srcMatch = scripts[index].match(/src=['"](.*?)['"]/);
        if (srcMatch) {
          script.src = srcMatch[1];
        }
      } else {
        const scriptContent = scripts[index]
          .replace(/<_script[\s\S]*?>|<\/_script>/gi, "")
          .trim();
        script.textContent = scriptContent;
      }

      script.onload = () => loadScriptsSequentially(scripts, index + 1);
      script.onerror = () => loadScriptsSequentially(scripts, index + 1);
      document.body.appendChild(script);
    };

    loadScriptsSequentially(scriptCodes);

    // eslint-disable-next-line
  }, []);

  return (
    <div className={cssClass} id={item.anchorName}>
      <div
        dangerouslySetInnerHTML={{ __html: nonScriptCode }}
        ref={iframeRef}
      ></div>
    </div>
  );
}
