import React, { useState } from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getBookingModel } from "./bookingFormModel";
import { gtm } from "../../../lib/tracking";
import { AddressAutocomplete } from "./addressAutocomplete";
import validators from "../../../lib/form/validators";

export function Booking(props) {
  const emailReceiver = props.emailReceiver;
  const fileAttachment = props.fileAttachment || "false";
  const mailchimpApiKey = props.mailchimpApiKey;
  const mailchimpAudienceKey = props.mailchimpAudienceKey;

  const model = getBookingModel({});
  const form = useForm(model, { usePlaceholder: true });
  // eslint-disable-next-line
  const [formModel, setFormModel] = useState(form.model);

  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [mailChimpError, setMailChimpError] = React.useState();
  const post = usePost();

  const mailChimpErrorMemo = React.useMemo(() => {
    return mailChimpError;
  }, [mailChimpError]);

  function onSubmit(e) {
    setMailChimpError(null);
    form.validateForm(e, () => {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(env.reCaptchaSiteKey, { action: "submit" })
          .then(function (token) {
            const value = {
              ...form.getValue(),
              emailReceiver: emailReceiver,
              mailchimpApiKey: mailchimpApiKey,
              mailchimpAudienceKey: mailchimpAudienceKey,
              grecaptchaToken: token,
            };

            var files = form.getFiles();
            var formData = new FormData();
            formData.append("jsonData", JSON.stringify(value));
            files.forEach((file) => {
              (file.files || []).forEach((uploadFile) => {
                formData.append(file.name, uploadFile);
              });
            });
            post.sendFormData(env.apiBase + "/api/contact/send2", formData);
            setSendingStatus("pending");
          });
      });
    });
  }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);

    if (
      post.response.results &&
      post.response.results.MailChimpError &&
      post.response.results.MailChimpError !== ""
    ) {
      setMailChimpError(
        "Mail Chimp Error : " + post.response.results.MailChimpError
      );
    }
  }

  const [formErrors, setFormErrors] = useState({});
  const setError = (fieldName, errorMessage) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const handlePickUpAddressChange = (e) => {
    const control = form.findControl("pickUpAddress");
    if (control) {
      control.touched = true;
      control.value = e.target.value;
      validators.validateControl(control, e, { triggeredByChange: true });
      const updatedModel = { ...form.model, pickUpAddress: control };
      setFormModel(updatedModel);
      form.model = updatedModel;
    }
  };

  const handleDropOffAddressChange = (e) => {
    const control = form.findControl("dropOffAddress");
    if (control) {
      control.touched = true;
      control.value = e.target.value;
      validators.validateControl(control, e, { triggeredByChange: true });
      const updatedModel = { ...form.model, dropOffAddress: control };
      setFormModel(updatedModel);
      form.model = updatedModel;
    }
  };

  const handleChange = (e, control) => {
    if (control) {
      control.touched = true;
      let value = e.target.value;
      const today = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toTimeString().slice(0, 5);
      if (control.name === "date") {
        if (value < today) {
          value = today;
        }
        if (value === today) {
          const timeControl = form.findControl("time");
          if (timeControl && timeControl.value < currentTime) {
            timeControl.value = currentTime;
            const updatedTimeModel = { ...form.model, time: timeControl };
            setFormModel(updatedTimeModel);
            form.model = updatedTimeModel;
          }
        }
      } else if (control.name === "time") {
        const dateControl = form.findControl("date");
        if (dateControl && dateControl.value === today) {
          if (value < currentTime) {
            value = currentTime;
          }
        }
      }
      control.value = value;
      validators.validateControl(control, e, { triggeredByChange: true });
      const updatedModel = { ...form.model, [control.name]: control };
      setFormModel(updatedModel);
      form.model = updatedModel;
    }
  };

  const render = (name) => {
    if (name === "pickUpAddress") {
      const pickUpAddressControl = form.findControl("pickUpAddress");
      return (
        <>
          <AddressAutocomplete
            name='pickUpAddress'
            value={pickUpAddressControl.value || ""}
            onChangeAddress={handlePickUpAddressChange}
            setError={setError}
          />
          {formErrors.pickUpAddress && (
            <div
              className='invalid error-message'
              role='alert'
              style={{ marginTop: "-1rem" }}
            >
              {formErrors.pickUpAddress}
            </div>
          )}
        </>
      );
    } else if (name === "dropOffAddress") {
      const dropOffAddressControl = form.findControl("dropOffAddress");
      return (
        <>
          <AddressAutocomplete
            name='dropOffAddress'
            value={dropOffAddressControl.value || ""}
            onChangeAddress={handleDropOffAddressChange}
            setError={setError}
          />
          {formErrors.dropOffAddress && (
            <div
              className='invalid error-message'
              role='alert'
              style={{ marginTop: "-1rem" }}
            >
              {formErrors.dropOffAddress}
            </div>
          )}
        </>
      );
    } else if (name === "date") {
      const dateControl = form.findControl("date");
      const today = new Date().toISOString().split("T")[0];
      return (
        <input
          className='time-input'
          type='date'
          name='date'
          min={today}
          value={dateControl.value || ""}
          onChange={(e) => handleChange(e, dateControl)}
        />
      );
    } else if (name === "time") {
      const timeControl = form.findControl("time");
      const dateControl = form.findControl("date");
      const today = new Date().toISOString().split("T")[0];
      const isToday = dateControl.value === today;
      const currentTime = new Date().toTimeString().slice(0, 5);
      return (
        <input
          className='time-input'
          type='time'
          name='time'
          min={isToday ? currentTime : undefined}
          value={timeControl.value || ""}
          onChange={(e) => handleChange(e, timeControl)}
        />
      );
    }
    return form.renderControl(name, null);
  };

  return (
    <form className='contactForm'>
      <div className='form-row'>
        <div className='col-md-6 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Name </label>
          {render("name")}
        </div>
        <div className='col-md-6 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Phone number </label>
          {render("phone")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-6 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Date of Pick-up </label>
          {render("date")}
        </div>
        <div className='col-md-6 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Time of Pick-up </label>
          {render("time")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Pick-up location </label>
          {render("pickUpAddress")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Drop-off location </label>
          {render("dropOffAddress")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}> Comments </label>
          {render("enquiry")}
        </div>
      </div>

      {fileAttachment === "true" && (
        <div className='form-row'>
          <div className='col-md-12 mb-2'> {render("attachments")} </div>
        </div>
      )}

      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Send Booking Request
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage='Please review the errors.'
            />
            <ErrorMessage errors={post.errors} />
            <ErrorMessage errors={mailChimpErrorMemo} />
            {post.done() && (
              <div
                style={{
                  marginTop: "1.5rem",
                  color: "#dccca5",
                  fontWeight: "bold",
                }}
              >
                <span>Successfully sent!</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
