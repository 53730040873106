// //import { css, keyframes  } from '@emotion/core'
// //import { variables, mq } from 'cssInJs'

import { css } from "@emotion/core";
import { mq } from "../../cssInJs";

export default {
  photoGallery: css({
    margin: "auto",
  }),
  thumbnails: css({
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "-5px",
    marginRight: "-5px",
  }),
  thumbnail: css(
    mq({
      flexBasis: ["50%", "25%"],
      padding: "5px",
      paddingBottom: "2rem",
      cursor: "pointer",
    })
  ),
};
