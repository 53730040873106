import { useStore } from '../../store';

// loc data: {path:'', title:''}
export function useHistoryStore() {
  const { state, dispatch } = useStore();
  const history = state.history || [];

  function push(loc) {
    const same = history.length > 0 
      && (JSON.stringify(history[history.length - 1]) === JSON.stringify(loc))
    if (!same) {
      // console.log(JSON.stringify(history[history.length - 1]), JSON.stringify(loc))
      setHistory([...history, loc]);
    }
  }

  function setHistory(history){
    // console.log('history set', history)
    dispatch({ type: 'SET_HISTORY', payload: history })
  }

  const last = () => {
    // console.log('last history', history)
    if (history.length < 2) return null;
    return history[history.length - 2].path;
  }

  const pop = () => {
    if (history.length === 0) return null;
    history.pop(); // remove the current first
    const item = history.pop();
    return item;
  }

  return { history, push, pop, last };
};