import { useState, useEffect } from 'react';
import utils from '../../utils';
import siteConfig from '../../config';

export function useApi(fn) {
  //console.log('useApi')
  const [response, setResponse] = useState({});
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState('ready');
  const [request, setRequest] = useState(null);

  useEffect(() => {
    if (!request) return;

    //console.log('fetch now')
    setResponse(null);
    setErrors([]);
    setStatus('ready');

    const fetchData = async () => {
      setStatus('pending');
      try {
        //console.log(request);
        const options = request.options || {}
        siteConfig.apiNetJwt && await siteConfig.apiNetJwt(options);

        const res = await fetch(request.url, options);
        //console.log(res);

        if (!res.ok) {
          try {
            const json = await res.json();
            console.log(json);

            if (json.message) {
              setErrors([json.message]);
              setStatus('error')
              return;
            }
          } catch (e) { }

          const statusText = res.status === 401 ? 'Permission denied. Please login first.' : res.statusText
          setErrors(['Error - [' + res.status + ']' + (statusText && (' - ' + statusText))]);
          setStatus('error')
          return;
        }

        //const json = await res.json();
        const text = await res.text();
        const json = text && text.length ? JSON.parse(text) : null;
        //console.log(json);

        // check if ajax result from c#
        if (json && json.errors && json.errors.length > 0) {
          setErrors(utils.form.getErrors(json));
          setStatus('error')
          return;
        }

        setResponse(json);
        setStatus('done')
      } catch (err) {
        //console.log(error, error.toString(), typeof error, JSON.stringify(error))
        setErrors([err.toString()]);
        setStatus('error')
      }
    };
    fetchData();
  }, [request]);

  const containsErrorMessage = (msg) => {
    const finding = (msg || '').toLowerCase()
    return errors.some(x => (x || '').toLowerCase().indexOf(finding) >= 0)
  }

  //return [ response, error, status, (...args) => setRequest(fn(...args)) ];
  return {
    request: request,
    response: response,
    errors: errors,
    status: status,
    hasErrors: () => status === 'error',
    containsErrorMessage: containsErrorMessage,
    addErrors: (errs) => {
      setErrors(...errors, ...errs)
      setStatus('error')
    },
    done: () => status === 'done',
    send: (...args) => { setStatus('ready'); setRequest(fn(...args)) },
    sendRaw: (httpOptionFn, ...args) => { setStatus('ready'); setRequest(httpOptionFn(...args)) },
    setResult: (json) => { setResponse(json); setStatus('done') },
    reset: () => {
      setStatus('ready');
      setErrors([]);
    }
  };
}

export function usePost() {
  const formDataFn = (url, data, options = {}) =>
    ({
      url: url,
      options: {
        ...options,
        method: 'POST',
        body: data // FormData
      }
    });
  const post = useApi((url, data, options = {}) =>
    ({
      url: url,
      options: {
        ...options,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }
    })
  );
  return {
    ...post,
    loading: () => post.status === 'ready' || post.status === 'pending',
    sending: () => post.status === 'pending',
    sendFormData: (...args) => post.sendRaw(formDataFn, ...args)
  }
}

export function useGet() {
  const get = useApi((url, options = {}) =>
    ({
      url: url,
      options: {
        ...options,
        method: 'GET'
      }
    })
  );
  return {
    ...get,
    loading: () => get.status === 'ready' || get.status === 'pending'
  }
}

//https://medium.com/@jaryd_34198/seamless-api-requests-with-react-hooks-part-2-3ab42ba6ad5c