import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

//#region manipulation
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
//#endregion

//#region format
export function formatDate(dateObj, formatStr) {
  const date = toDate(dateObj);
  if (!date) return "";
  return format(date, formatStr);
}

export function formatYYYYMMDDToday() {
  const date = new Date();
  //console.log(dateObj, date)
  if (!date) return '';
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  } 
  if (mm < 10) {
    mm = '0' + mm;
  } 
  return (`${yyyy}-${mm}-${dd}`)
}

export function formatYYYYMMDD(dateObj) {
  const date = toDate(dateObj);
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${yyyy}-${mm}-${dd}`;
}

export function formatDDMMYYYY(dateObj) {
  const date = toDate(dateObj);
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}-${mm}-${yyyy}`;
}
//#endregion

//#region parse
export function toDate(dateObj) {
  if (!dateObj) return null;
  if (typeof dateObj === "string") {
    return (
      parseDate(dateObj, "yyyy-MM-dd") ||
      parseDate(dateObj, "yyyy-M-d") ||
      parseDate(dateObj, "dd/MM/yyyy") ||
      parseDate(dateObj, "d/M/yyyy") ||
      parseISO(dateObj)
    );
  } else {
    return dateObj;
  }
}

export function toDateTime(dateObj) {
  if (!dateObj) return null;
  if (typeof dateObj === "string") {
    return (
      parseDate(dateObj, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") ||
      parseDate(dateObj, "yyyy-MM-dd HH:mm:ss.SSSxxx") ||
      parseDate(dateObj, "yyyy-M-d HH:mm:ss.SSSxxx") ||
      parseDate(dateObj, "dd/MM/yyyy HH:mm:ss.SSSxxx") ||
      parseDate(dateObj, "d/M/yyyy HH:mm:ss.SSSxxx") ||
      parseISO(dateObj)
    );
  } else {
    return dateObj;
  }
}

export function parseDate(dateStr, format) {
  const date = parse(dateStr, format, new Date());
  return date && isValidDate(date) ? date : null;
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
//#endregion
