import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";

export function BottomDock(props) {
  const links = [
    {
      url: "/",
      title: "HOME",
      icon: "home-icon.png",
      iconHover: "home-gold.png",
    },
    {
      url: "/about-us",
      title: "ABOUT US",
      icon: "about-us.png",
      iconHover: "about-us-gold.png",
    },
    {
      url: "/bookings",
      title: "BOOKINGS",
      icon: "bookings-icon.png",
      iconHover: "bookings-gold.png",
    },
    {
      url: "/gallery",
      title: "GALLERY",
      icon: "gallery-icon.png",
      iconHover: "gallery-gold.png",
    },
    {
      url: "/contact-us",
      title: "CONTACT US",
      icon: "contact-icon.png",
      iconHover: "contact-us-gold.png",
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className='bottomMenu'>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
    </>
  );
}
